<template>
  <div>
    <ListHeader entity-name="systemFeedback"></ListHeader>
    <div class="default-according style-1 faq-accordion default-accordion" id="accordionoc">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card class="m-b-20" no-body v-for="(feedback, id) in records.items" :key="id">
              <CardHeader :id="id" model-name="systemFeedback" :record="feedback"
                          @sendRecord="sendRecord"></CardHeader>
              <b-collapse :id="`${id}`" role="tabpanel" @shown="markFeedbackAsRead(feedback)">
                <b-card-body class="pre">

                  <div class="row">
                    <p class="col-3 p-r-0 mb-2">
                      <strong>{{ $t('models.systemFeedback.attributes.content') }}</strong>
                    </p>
                    <p class="col-9 mb-2" v-text="feedback.content"></p>
                  </div>

                  <div class="row">
                    <p class="col-3 p-r-0 mb-2">
                     <strong>{{ $t('models.systemFeedback.attributes.rating') }}</strong>
                    </p>
                    <div>
                      <span class="col-9 mb-2" v-for="n in 5" :key="n">
                        <i class="fa fa-star"
                           :class="`${feedback.rating >= n ? 'font-warning' : 'font-warning-o'}`">
                        </i>
                      </span>
                    </div>
                  </div>

                  <div class="row">
                    <p class="col-3 p-r-0 mb-2">
                      <strong> {{ $t("models.systemFeedback.attributes.positive_feedback") }} </strong>
                    </p>
                    <p class="col-9 mb-2" v-text="feedback.positiveFeedback"></p>
                  </div>

                  <div class="row">
                    <p class="col-3 p-r-0 mb-2">
                      <strong> {{ $t("models.systemFeedback.attributes.negative_feedback") }} </strong>
                    </p>
                    <p class="col-9 mb-2" v-text="feedback.negativeFeedback"></p>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <div class="pagination" v-if="countNotZero">
              <pagination :options="options" :pages="pages" @paginate="paginate"/>
            </div>
            <div v-else>
              {{ $t('messages.error.noContent') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import ListsMixin from 'mixins/ListsMixin';

export default {
  name:'SystemFeedbackList',
  data() {
    return {
      modelName: 'systemFeedback'
 	  };
  },
  computed: {
    ...mapGetters({
      records: 'systemFeedbacks'
    })
  },
  methods: {
 	...mapActions({
      getRecords: 'getSystemFeedbacks',
      markAsRead: 'markAsRead'
    }),
    markFeedbackAsRead(feedback) {
      if(! feedback.seen) {
        this.markAsRead(feedback.id).then(() => {
          this.fetchAndPaginateRecords();
        });
      }
    }
  },
  mixins: [ListsMixin]
};
</script>
